import { createSlice } from '@reduxjs/toolkit'

export const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    value: {
        messages : [
        { id: 1, text: "Hi, welke vragen over deze returns kan ik voor je beantwoorden?", sender: "bot" }
        ],
        messageButtonLoading: false,
        messageHistory: []
    }
  },
  reducers: {
    addMessage: (state, action) => {state.value.messages = [...state.value.messages, action.payload]},
    alterMessageButtonLoading: (state, action) => {state.value.messageButtonLoading = action.payload},
    addMessageToHistory: (state, action) => {state.value.messageHistory = [...state.value.messageHistory, action.payload]},
  },
})

export const {
        addMessage,
        alterMessageButtonLoading,
        addMessageToHistory
    } = formDataSlice.actions


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
//export const selectFormdata = (state) => state.formData.value
export const selectFormdata = (state) => state.formData.value


//export default {activeDataSliceReducer, formDataSliceReducer, selectActiveData, selectFormdata}
export default formDataSlice.reducer