import { Box, Login, Button } from "@coolblue-development/becky";
import { BeckyRound } from '@coolblue-development/icons';

export default function LoginComponent() {

    function handleCick() {
        window.location.reload();
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
            background: '#ffffff',
            padding: 6,
            }}
        >
            <Login
                logo={<BeckyRound />}
                title="Custom analysis tool"
                button={
                    <Button onClick={handleCick} >
                        Login (VPN required)
                    </Button>}
            / >
        </Box>
    )
}