import axios from 'axios';

const instance = axios.create({
    baseURL : 'http://127.0.0.1:2000'
});

if (!process.env.REACT_APP_BACKEND_HOST_URL) {
    instance.defaults.baseURL = 'http://127.0.0.1:2000';
} else {
    instance.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST_URL;
}

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';


export default instance;