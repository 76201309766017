import React, { useState, useEffect }  from "react";
import {
    Avatar,
    Box,
    Drawer,
    TextField,
    Button,
    Typography,
    Grid,
    Tab,
    Tabs,
    TabPanel
} from "@coolblue-development/becky";
import { Becky } from '@coolblue-development/icons';
import {red, green, blue, gray, peppermint, antarcticblue} from '../Components/Colors'
import MessageBar from "../Components/MessageBar";
import Conversation from "../Components/Conversation";
import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import { selectFormdata } from "../Form/FormSlice";
import Login from "../Components/Login";


export default function Details() {
    const formData = useSelector(selectFormdata);
	const messages = formData.messages;
    const [value, setValue] = React.useState(0);
	const handleTabChange = (_event: SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

    const [content, setContent] = useState('');
    const [open, setOpen] = useState(false);

	const handleOpen = (fieldTopic): void => {
		setOpen(true);
	};
    const handleClose = (): void => {
      setOpen(false);
    };
    let src_param = window.location.search;

        return (
            <>
            <Tabs value={value} onChange={handleTabChange}>
                <Tab label="Dashboard" />
                <Tab label="Hierarchy" />
                <Tab label="Complaints" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <iframe
                    src={"https://coolblue.cloud.looker.com/embed/dashboards/9415" + src_param}
                    width="100%"
                    height="700px"
                    frameBorder="0">
                </iframe>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <iframe
                    src={"https://coolblue.cloud.looker.com/embed/dashboards/9520" + src_param}
                    width="100%"
                    height="700px"
                    frameborder="0">
                </iframe>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <iframe
                    src={"https://coolblue.cloud.looker.com/embed/dashboards/9298" + src_param}
                    width="100%"
                    height="700px"
                    frameborder="0">
                </iframe>
            </TabPanel>
            <Avatar sx={{position: "fixed", bottom: "50px;", right:"50px;", height: "60px;", width: "60px;" }} onClick={handleOpen}><Becky /></Avatar>
            <Drawer
              title="Chat Dialog"
              open={open}
              onClose={(): void => {
                handleClose(false);
              }}
              footer={<MessageBar/>}
            >
               <Conversation/>
            </Drawer>
            </>
        );
}


