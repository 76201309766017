import { AuthConfigInterface } from '@coolblue-development/react-cognito-sso-auth';

export const getAuthConfig = (): AuthConfigInterface => {
    let user_pool;
    let oidc;
    let domain;

    if (!process.env.REACT_APP_COGNITO_DOMAIN) {
        domain = process.env.REACT_APP_COGNITO_DOMAIN = 'pm-ai-tools-testing.auth.eu-west-1.amazoncognito.com/';
        oidc = '752b446ldtm4820kldv7ia74v2';
        user_pool = 'eu-west-1_hQvX5woeu';
    } else {
        domain = process.env.REACT_APP_COGNITO_DOMAIN;
        oidc = process.env.REACT_APP_OIDC_CLIENT_ID;
        user_pool = process.env.REACT_APP_USERPOOL_ID;
    }

    const currentHostUrl = `${window.location.protocol}//${window.location.host}`;
    console.log(currentHostUrl)
    return {
          region: 'eu-west-1',
          userPoolId: user_pool,
          userPoolWebClientId: oidc,
          oauth: {
            domain: domain,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: currentHostUrl + "/",
            redirectSignOut: currentHostUrl + '/logout/',
            responseType: 'code',
            client_id: oidc
          }
        }
};



