import * as React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid
  } from "@coolblue-development/becky";
import { Paper } from "@mui/material";
import {red, green, blue, gray, peppermint, antarcticblue} from './Colors'
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import { selectFormdata } from "../Form/FormSlice";


const Conversation = () => {
    const formData = useSelector(selectFormdata);
    const messages = formData.messages;
    const messageArray = Object.values(messages);
    const [input, setInput] = React.useState("");

    const handleSend = () => {
        if (input.trim() !== "") {
            console.log(input);
            setInput("");
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const Message = ({ message }) => {
        const isBot = message.sender === "bot";

        return (
            <>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: isBot ? "flex-start" : "flex-end",
                        fontSize: "10px",
                        color: gray
                    }}>
                    <p>{message.sender}</p>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: isBot ? "flex-start" : "flex-end",
                        mb: 2,
                    }}
                >
                    <Paper
                        variant="outlined"
                        sx={{ p: 1, borderColor: isBot ? peppermint : antarcticblue }}
                    >
                        <Typography sx={{whiteSpace:"pre-line;"}} variant="body1">{message.text}</Typography>
                    </Paper>
                </Box>
            </>
        );
    };

    return (
        <Box sx={{  display: "flex", flexDirection: "column" }}>
            <Box sx={{ flexGrow: 1, overflow: "auto", p: 2 }}>
                {messageArray.map((message) => (
                    <Message message={message} />
                ))}
            </Box>
        </Box>
    );
};

export default Conversation;