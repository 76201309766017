import React, { useState, useEffect } from "react"
import { selectFormdata, addMessage, alterMessageButtonLoading, addMessageToHistory } from "../Form/FormSlice";
import axios from "../Auth/axiosConfig";

export default function AnotherQuestion(input, messageHistory, messages, dispatch, token) {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const base = process.env.REACT_APP_BACKEND_HOST_URL;
    const pathname = '/question'
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const data = {question: input, conversation: messageHistory}
    dispatch(alterMessageButtonLoading(true));
    axios.post(pathname + '?' + params, { data })
        .then(function(result) {
            let response_data = { id: messages.length + 2, text: result.data.answer, sender: "bot" }
            dispatch(addMessage(response_data));
            dispatch(addMessageToHistory({'question': result.data.question, 'answer': result.data.answer}))
            dispatch(alterMessageButtonLoading(false));
        })
}