import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import { Button, Box, Login as LoginComponent } from '@coolblue-development/becky';
import { BeckyRound } from '@coolblue-development/icons';
import { Paper } from '@mui/material';

const Login = () => {
  const { onLogin } = useAuth();

  return (
 <Box
  display="flex"
  alignItems="center"
  justifyContent="center"
  sx={{
    background: '#ffffff',
    padding: 6,
  }}
>
  <LoginComponent logo={<BeckyRound />} title="Product Managment AI Tools" button={
    <Button
        onClick={onLogin}
    >
    Login</Button>}
    / >
</Box>);
};

export default Login;