import { Box } from "@coolblue-development/becky";
import { BeckyThemeProvider } from '@coolblue-development/themes';
import {
    RouterProvider
} from "react-router-dom";

import { AuthProvider, useAuth } from '@coolblue-development/react-cognito-sso-auth';

import { getAuthConfig } from "./Auth/AuthConfig"
import router from './router';
import Dashboard from "./Screens/Dashboard";
import AppBarComponent from "./Components/Appbar";

const authConfig = getAuthConfig();

export default function Main(){
    return (
        <BeckyThemeProvider>
            <AuthProvider config={authConfig}>
                <AppBarComponent />
                <Box sx={{ bgcolor: 'background.paper', padding: 6, margin: 6 }} >
                    <RouterProvider router={router} />
                </Box>
            </AuthProvider>
        </BeckyThemeProvider>
    );
}