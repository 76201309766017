import { createBrowserRouter, Outlet, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@coolblue-development/react-cognito-sso-auth';
import { routes } from './routes';

import Login from './Screens/Login';
import Dashboard from './Screens/Dashboard';


export default createBrowserRouter([
    {
        path: routes.LOGIN,
        element: <Login />,
    },
    {
    path: routes.ROOT,
    element: (
      <ProtectedRoute
        renderLoadingPage={<div>Loading</div>}
        renderUnauthorizedPage={<Navigate to="/login" />}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    errorElement: <Dashboard />,
    children: [
        {
            path: routes.ROOT,
            element: <Dashboard />,
        }
        ],
    }
]);